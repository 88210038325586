// Removes space under images
.p-image-preview {
  display: flex !important;
}

// Fix overly wide dialog content
.p-dialog-content {
  max-width: 100vw;
}

// Makes the skeleton full width actually display
.lc-p-full-width-skeleton {
  display: contents;
}
