@use "media-queries" as m;

.p-card-title {
  @include m.on-mobile {
    margin-bottom: 0 !important;
  }
}

.black-font {
  color: #000000;
}

a.title-link {
  text-decoration: none;
  color: var(--p-surface-900);
  &:hover {
    text-decoration: underline;
    cursor: pointer;
    color: var(--highlight-text-color);
  }
}
